<template>
  <lock-scroll :locked="lockScroll">
    <lock-screen :locked="lockScreen" />
    <app-layout>
        <div class="flex flex-col items-center py-4" :class="{ 'cursor-not-allowed': disabled }">
        <div class="w-full lg:w-4/5 px-4">
          <div class="text-center">
            <span class="text-xl font-bold text-gray-500">
              {{ today }}
            </span>
          </div>
          <div class="text-center py-2">
            <span class="text-xl font-bold text-gray-500">
              Saludos, {{ fullname }}
            </span>
          </div>
          <div class="flex flex-col lg:flex-row py-2 lg:space-x-2">
            <card-button
              :disabled="disabled"
              class="w-full align-middle text-center lg:w-1/3 mb-2"
              name="orders.index"
              :params="{ status: `${restrictedStatuses.PendingAssignment}` }">
              <i class='text-3xl bx bx-group align-bottom text-purple-500'></i>
              <span class="text-xl font-bold ml-2">{{ counts.pendingAssignment }}</span>
              <span class="text-xl font-bold ml-2">Por Asignar</span>
            </card-button>
            <card-button
              :disabled="disabled"
              class="w-full align-middle justify-center text-center  lg:w-1/3 mb-2"
              name="orders.index"
              :params="{ status : `${restrictedStatuses.InProgress}` }">
              <i class='text-3xl bx bx-timer align-bottom text-purple-500 '></i>
              <span class="text-xl font-bold ml-2">{{ counts.inProgress }}</span>
              <span class="text-xl font-bold ml-2">En Proceso</span>
            </card-button>
            <card-button
              :disabled="disabled"
              class="w-full align-middle justify-center text-center  lg:w-1/3 mb-2"
              name="orders.index"
              :params="{ status: `${restrictedStatuses.ToBeDelivered}` }">
              <i class='text-3xl bx bx-package align-bottom text-purple-500'></i>
              <span class="text-xl font-bold ml-2">{{ counts.toBeDelivered }}</span>
              <span class="text-xl font-bold ml-2">Por Entregar</span>
            </card-button>
          </div>
          <div class="w-full flex flex-col lg:flex-row space-y-6">
            <div class="w-full lg:w-1/2">
              <chart :options="chartOptions" />
            </div>
            <div class="w-full lg:w-1/2" style="align-self: center">
              <div class="w-full flex flex-row" style="justify-content: space-around">
                <div class="text-center text-2xl">
                  <h1><strong>Ventas</strong></h1>
                  <h2>{{ moneyFormat(totalSale) }}</h2>
                </div>
                <div class="text-center text-2xl">
                  <h1><strong>Finalizados</strong></h1>
                  <h2>{{ counts.finalized }}</h2>
                </div>
              </div>
              <div class="w-full flex flex-row justify-center">
                <div
                  class="border border-purple-500 text-xl text-center cursor-pointer"
                  :class="{ 'bg-purple-500 text-white': active === 1, 'cursor-not-allowed': disabled }"
                  style="min-width: 100px; width: 100px;"
                  @click="changeActive(1)">
                  Hoy
                </div>
                <div
                  class="border border-purple-500 text-xl text-center cursor-pointer"
                  :class="{ 'bg-purple-500 text-white': active === 2, 'cursor-not-allowed': disabled }"
                  style="min-width: 100px; width: 100px;"
                  @click="changeActive(2)">
                  Semana
                </div>
                <div
                  class="border border-purple-500 text-xl text-center cursor-pointer"
                  :class="{ 'bg-purple-500 text-white': active === 3, 'cursor-not-allowed': disabled }"
                  style="min-width: 100px; width: 100px;"
                  @click="changeActive(3)">
                  Mes
                </div>
              </div>
              <div class="w-full flex flex-row justify-center mt-4">
                <el-button @click="closeDay">
                  Cerrar Dia
                </el-button>
              </div>
            </div>
          </div>
          <div class="flex flex-col space-y-6">
            <content-card title="Órdenes con fecha límite en esta semana" with-options v-loading.fullscreen.lock="loading">
              <div class="py-2">
                <div>
                  <orders-grid :hasActions="!disabled" :orders="orders" @onStatusUpdate="updateStatus" @onSorting="handleSorting" />
                </div>
              </div>
            </content-card>
          </div>
        </div>
      </div>
    </app-layout>
    <el-dialog :visible.sync="showQuickStartDialog" width="75%" @close="start">
      <div class="text-center">
        <div>
          <span class="text-5xl text-gray-500 font-bold">Bienvenido a Samii</span>
        </div>
        <div>
          <img src="@/assets/img/welcome-samii.png" width="320px" class="m-auto" />
        </div>
        <div>
          <span class="text-4xl text-gray-400">
            El primer software para técnicos hecho por un técnico.
          </span>
        </div>
        <div class="w-full">
          <div class="text-right">
            <el-button class="el-button--primary" @click="start">Crear mi Primer Orden</el-button>
          </div>
        </div>
      </div>
    </el-dialog>
  </lock-scroll>
</template>
<script>
import CardButton from '@/components/molecules/CardButton';
import { DateFormats } from '@/constants';
import HomeService from '@/services/HomeService';
import OrderService from '@/services/OrderService';
import BusinessService from '@/services/BusinessService';
import OrdersGrid from '@/views/app/orders/grid/Index.vue';
import { Chart } from 'highcharts-vue'
import numbers from '@/utils/numbers';
import error from '@/mixins/error';
import { QUICK_START_STORE } from '@/store/modules/quick-start/mutation-types';
import OrderPaymentDialogService, {
  command as OrderPaymentDialogCommand,
  commands as OrderPaymentDialogCommands,
} from '@/components/dialogs/OrderPayment/service';
import {
  LOCAL_STORAGE_SAMII_KEYS, getItem, setItem,
} from '@/utils/storage';
import {
  getRestrictedStatuses,
} from '@/helpers/order-status.helper';

export default {
  name: 'HomeIndex',
  mixins: [error],
  components: {
    OrdersGrid,
    AppLayout: () => import('@/components/layouts/AppLayout'),
    ContentCard: () => import('@/components/molecules/ContentCard'),
    CardButton,
    Chart,
  },
  data() {
    return {
      loading: false,
      active: 1,
      orders: [],
      filters: {
        dueDateEnd: this.$moment(new Date()).format('MM/DD/YYYY'),
        dueDateStart: this.$moment(new Date()).format('MM/DD/YYYY'),
      },
      totalSale: 0,
      counts: {
        finalized: 0,
        inProgress: 0,
        pendingAssignment: 0,
        toBeDelivered: 0,
      },
      chartOptions: {
        colors: [
          '#FCA5A5',
          '#6EE7B7',
          '#A5B4FC',
        ],
        credits: {
          enabled: false
        },
        chart: {
          type: "pie",
          options3d: {
            enabled: false,
            alpha: 45
          },
          margin: [0,0,0,15],
          spacingTop: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          spacingRight: 0,
        },
        title: {
          text: null
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 10
          }
        },
        series: [
          {
            name: "Estado",
            data: [
              ["Por Asignar", 0],
              ["En Proceso", 0],
              ["Por Entregar", 0],
            ]
          }
        ]
      },
      lockScroll: false,
      lockScreen: false,
      showQuickStartDialog: false,
      subscription: null,
    };
  },
  computed: {
    today() {
      const format = this.$store.state.business.dateFormat?.toUpperCase() ?? "MM/DD/YYYY";
      return this.$moment(new Date()).format(format);
    },
    fullname() {
      return this.$store.state.account.fullname;
    },
    disabled() {
      return this.$store.state.account.membershipLimitReached;
    },
    restrictedStatuses() {
      const {
        PendingAssignment,
        InProgress,
        ToBeDelivered,
        Finalized
      } = getRestrictedStatuses(this.$store.state.catalogs.orderStatuses);

      return {
        PendingAssignment,
        InProgress,
        ToBeDelivered,
        Finalized
      };
    },
  },
  created() {
    this.subscription = OrderPaymentDialogCommand.subscribe(this.handleOrderPaymentDialogCommands);
  },
  destroyed() {
    this.subscription.unsubscribe();
  },
  mounted() {
    const quickStartStatus = getItem(LOCAL_STORAGE_SAMII_KEYS.QUICK_START_STATUS);
    if (!quickStartStatus || quickStartStatus === 'done') {
      const dateFormatStatus = getItem(LOCAL_STORAGE_SAMII_KEYS.DATE_FORMAT_STATUS);
      if (!dateFormatStatus || dateFormatStatus === 'undone') {
        this.showDateFormatSelector();
      }
      this.load();
    } else {
      this.showQuickStartDialog = true;
      // this.lockScroll = true;
      this.lockScreen = true;
    }
  },
  methods: {
    async showDateFormatSelector() {
      const { value: result } = await this.$swal.fire({
        html: `
          <strong>Configuración de Formato de Fechas</strong>
          <br>
          Ahora puedes configurar el formato de la fecha para tu negocio
        `,
        input: "select",
        inputOptions: DateFormats,
        showCancelButton: false,
        allowOutsideClick: false,
        showLoaderOnConfirm: true,
        preConfirm: async (dateFormat) => {
          try {
            await BusinessService.saveDateFormat({ dateFormat: DateFormats[dateFormat] });
            return { success: true, value: dateFormat };
          } catch (error) {
            return { success: false, message: "No se pudo actualizar el formato de fecha" };
          }
        },
      });
      if (result.success) {
        this.$toastr.s(`Se configuró con exito: <br> Formato <strong>${DateFormats[result.value]}</strong>`);
        setItem(LOCAL_STORAGE_SAMII_KEYS.DATE_FORMAT_STATUS, 'done');
      } else {
        this.$toastr.e(result.message);
      }
    },
    // TODO update this logic with src/utils/dates.js
    // Example on src/views/app/finances/Finances.vue
    async updateFilters() {
      const init = new Date();
      const last = new Date();
      if (this.active === 1) {
        this.filters.dueDateStart = this.$moment(init).format('MM/DD/YYYY');
        this.filters.dueDateEnd = this.$moment(last).format('MM/DD/YYYY');
      }
      if (this.active === 2) {
        const firstDayOfWeek = init.getDate() - init.getDay();
        const lastDayOfWeek = firstDayOfWeek + 6;
        this.filters.dueDateStart = this.$moment(new Date(init.setDate(firstDayOfWeek))).format('MM/DD/YYYY');
        this.filters.dueDateEnd = this.$moment(new Date(last.setDate(lastDayOfWeek))).format('MM/DD/YYYY');
      }
      if (this.active === 3) {
        const month = init.getMonth();
        const year = init.getFullYear();
        this.filters.dueDateStart = this.$moment(new Date(year, month, 1)).format('MM/DD/YYYY');
        this.filters.dueDateEnd = this.$moment(new Date(year, month + 1, 0)).format('MM/DD/YYYY');
      }
    },
    async changeActive(index) {
      if (this.disabled) return;
      this.active = index;
      await this.updateFilters();
      await this.load();
    },
    async load() {
      try {
        const dashboard = await HomeService.Dashboard(this.filters);
        this.orders = dashboard.orders.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        this.totalSale = dashboard.totalSales;
        this.counts = dashboard.totalsAmount;
        this.chartOptions.series = [{
          name: "Estado",
          data: [
            ["Por Asignar", this.counts.pendingAssignment],
            ["En Proceso", this.counts.inProgress],
            ["Por Entregar", this.counts.toBeDelivered],
          ],
        }];

        const business = await this.$store.state.business;
        if (!business.timezone) {
          this.$swal.fire({
            title: 'Falta Información en tu Perfil',
            icon: 'info',
            text: 'Ahora puedes indicar la zona horaria en la que te encuentras. Puedes modificarlo desde tu perfil.',
            confirmButtonText: '<i class="fa fa-thumbs-up"></i> OK',
          }).then(() => {
            this.$router.push({ name: 'admin.index', params: { slot: 'my-business' } });
          });
        }
      } catch (e) {
        this.$toastr.e(this.getErrorMessage(e));
      }
    },
    async updateOrderStatus({ orderId, status, paymentMethod = undefined }) {
      await OrderService.updateStatus({ id: orderId, status, paymentMethod });
      this.$toastr.s('Se actualizo con exito');
      await this.load();
    },
    updateStatus({ orderId, status }) {
      this.loading = true;
      try {
        const { Finalized } = this.restrictedStatuses;
        if (status === Finalized.id) {
          OrderPaymentDialogService.show({ orderId, status });
        } else {
          this.updateOrderStatus({ orderId, status });
        }
      } catch (e) {
        this.$toastr.e(this.getErrorMessage(e));
      } finally {
        this.loading = false;
      }
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    handleSorting(sorting) {
      const keys = Object.keys(sorting);
      const direction = sorting[keys];
      this.orders = this.orders.sort((a, b) => {
        if (direction === 'desc') return new Date(b.createdAt) - new Date(a.createdAt);
        return new Date(a.createdAt) - new Date(b.createdAt);
      });
    },
    start() {
      this.showQuickStartDialog = false;
      this.$store.dispatch(QUICK_START_STORE, { step: 1 });
    },
    closeDay() {
      this.$router.push({ name: 'close.day' });
    },
    handleOrderPaymentDialogCommands({ type, payload }) {
      switch (type) {
        case OrderPaymentDialogCommands.Select:
          this.updateOrderStatus({
            orderId: payload.orderId,
            status: payload.status,
            paymentMethod: payload.paymentMethod,
          });
          break;
        default:
      }

    },
  },
}
</script>
