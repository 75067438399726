import HttpProxy from '@/services/HttpProxy';
import NotificationService from '@/services/NotificationService';

export default class HomeService {
  static async Dashboard(parameters) {
    const response = await HttpProxy.submit({
      method: 'get',
      url: 'Orders/Dashboard',
      parameters,
    });
    await NotificationService.get();
    return response?.data ?? null;
  }
}
