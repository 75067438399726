<template>
  <div class="block w-full overflow-x-auto">
    <table class="items-center w-full border-collapse">
      <thead>
        <tr class="px-2 border-b-2">
          <th v-if="hasActions" class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
          </th>
          <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
            Orden
          </th>
          <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
            <div class="w-full flex flex-row">
              <span>Fecha</span>
              <div class="mx-2 font-normal cursor-pointer" @click="sort('createdDate')">
                <i v-if="sorting.createdDate === 'desc'" class="text-lg bx bx-sort-down text-purple-500" />
                <i v-if="sorting.createdDate === 'asc'" class="text-lg bx bx-sort-up text-purple-500" />
              </div>
            </div>
          </th>
          <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
            Cliente
          </th>
          <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
            Asignado a
          </th>
          <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
            Modelo
          </th>
          <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
            Status
          </th>
          <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
            Prioridad
          </th>
          <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
            Fecha compromiso
          </th>
          <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
            Fecha Entregado
          </th>
          <th class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap">
            Total
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="order in orders"
          :key="order.id">
          <td v-if="hasActions" class="p-2 border-t" style="min-width: 50px; width: 50px;">
            <el-popover
              v-if="!order.deletedAt"
              placement="right"
              width="100"
              trigger="hover">
              <div class="w-full flex flex-row text-center">
                <div class="w-1/4">
                  <button
                    v-if="$store.state.account.canEditOrders"
                    title="Editar"
                    class="el-button--primary rounded-full cursor-pointer"
                    @click="onEdit(order)">
                    <i class="bx bx-edit" style="padding: 4px; font-size: 20px;"></i>
                  </button>
                  <button
                    v-if="!$store.state.account.canEditOrders"
                    title="Visualizar"
                    class="el-button--primary rounded-full cursor-pointer"
                    @click="onEdit(order)">
                    <i class="bx bx-low-vision" style="padding: 4px; font-size: 20px;"></i>
                  </button>
                </div>
                <div class="w-1/4">
                  <button
                    title="Imprimir"
                    class="el-button--warning rounded-full cursor-pointer"
                    @click="onPrint(order)">
                    <i class="bx bx-printer" style="padding: 4px; font-size: 20px;"></i>
                  </button>
                </div>
                <div class="w-1/4">
                  <button
                    v-if="$store.state.account.canEditOrders"
                    title="Eliminar"
                    class="el-button--danger rounded-full cursor-pointer"
                    @click="onDelete(order.id)">
                    <i class="bx bx-trash" style="padding: 4px; font-size: 20px;"></i>
                  </button>
                  <button
                    v-if="!$store.state.account.canEditOrders"
                    title="Eliminar"
                    class="el-button--danger rounded-full cursor-not-allowed"
                    disabled>
                    <i class="bx bx-trash" style="padding: 4px; font-size: 20px;"></i>
                  </button>
                </div>
                <div class="w-1/4">
                  <button
                    title="Notas"
                    class="el-button--info rounded-full cursor-pointer"
                    @click="showOrderNotesManager(order.id)">
                    <i class="bx bx-note" style="padding: 4px; font-size: 20px;"></i>
                  </button>
                </div>
              </div>
              <el-button slot="reference" type="text" class="w-full">
                <button
                  class="el-button--primary rounded-full cursor-pointer">
                  <i class="text-2xl bx bx-chevron-right"></i>
                </button>
              </el-button>
            </el-popover>
            <div v-else class="text-center">
              <i class="bx bx-trash text-xl text-red-400"></i>
            </div>
          </td>
          <td
            v-if="!referenceCanRedirectOrder"
            class="p-2 border-t"
            :class="{ 'text-red-400': order.deletedAt }"
            style="min-width: 125px">
            {{ order.reference }}
          </td>
          <td
            v-if="referenceCanRedirectOrder"
            class="p-2 border-t"
            :class="{ 'text-red-400': order.deletedAt }"
            style="min-width: 125px">
            <a
              :class="{ 'hover:text-red-400 cursor-not-allowed': order.deletedAt }"
              @click="$router.push({ name: 'orders.read', params: { id: order.id } })">
              {{ order.reference }}
            </a>
          </td>
          <td class="p-2 border-t" style="min-width: 155px;">
            <el-date-picker
              v-model="order.createdAt"
              type="date"
              disabled
              :format="$store.state.business.dateFormat"
              class="w-full bg-select-button">
            </el-date-picker>
          </td>
          <td class="p-2 border-t text-left">
            <el-popover
              v-if="hasActions"
              placement="bottom"
              width="250"
              trigger="click">
              <div class="flex flex-col">
                <div class="flex flex-col py-4">
                  <span class="font-bold">{{ order.client.fullName }}</span>
                  <span class="text-purple-500 underline">{{ order.client.email }}</span>
                </div>
                <div class="py-2 border-t" v-if="canRedirectClientOrders">
                  <sa-button
                    type="secondary"
                    class="w-full"
                    @click="$router.push({ name: 'orders.client', params: { id: order.client.id, fullName: order.client.fullName } })">
                    Ver cliente
                  </sa-button>
                </div>
              </div>
              <el-button
                slot="reference"
                type="text"
                class="text-left">
                <span>{{ order.client.fullName }}</span>
                <i class='bx bx-chevron-down'></i>
              </el-button>
            </el-popover>
            <span v-else>{{ order.client.fullName }}</span>
          </td>
          <td class="p-2 border-t text-left" style="min-width: 125px;">
            {{ getAssignedAccountName(order) }}
          </td>
          <td class="p-2 border-t text-left" style="min-width: 100px;">
            {{ getDeviceModel(order) }}
          </td>
          <td class="p-2 border-t" style="min-width: 160px;">
            <el-select
              v-if="hasActions && !order.deletedAt"
              :value="order.orderStatus?.name"
              @change="updateStatus(order.id, $event)"
              v-loading.fullscreen.lock="loading"
            >
              <div
                slot="prefix"
                class='mt-2 text-xl rounded-sm badge'
                :style="{ 'background-color': order.orderStatus?.colour }"
              />
              <el-option
                v-for="status in filteredStatuses"
                :key="status.id"
                :value="status.id"
                :label="status.name">
                <div style="display: inline-flex;">
                  <div
                    class="mt-2 text-xl rounded-sm badge"
                    :style="{ 'background-color': status.color }" />
                  <div>{{ status.name }}</div>
                </div>
              </el-option>
            </el-select>
            <div v-else class="flex flex-row w-full items-center">
              <div class="mt-2 text-xl rounded-sm badge" :style="{ 'background-color': getOrderStatusColor(order.orderStatus) }" />
              <div>{{ order.orderStatus.name }}</div>
            </div>
          </td>
          <td class="p-2 border-t" style="min-width: 100px;">
            {{ priorities[order.priority] }}
          </td>
          <td class="p-2 border-t" style="min-width: 155px;">
            <el-date-picker
              v-model="order.dueDate"
              type="date"
              disabled
              :format="$store.state.business.dateFormat"
              class="w-full bg-select-button">
            </el-date-picker>
          </td>
          <td class="p-2 border-t" style="min-width: 155px;">
            <el-date-picker
              v-model="order.finalizedStatusAt"
              type="date"
              disabled
              :format="$store.state.business.dateFormat"
              class="w-full bg-select-button">
            </el-date-picker>
          </td>
          <td class="p-2 border-t" style="min-width: 110px;">
            {{ moneyFormat(order.subtotalAmount + order.taxAmount) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<style scoped>
.badge {
  margin: 12px 4px;
  width: 12px;
  height: 12px;
}
</style>
<script>
import { PriorityTypeEnum } from '@/constants';
import numbers from '@/utils/numbers';
import OrderNotesManagerService from '@/components/dialogs/OrderNotesManager/service';
import { Whatsapp, getCountryCode } from '@/utils/phones';
import { getRestrictedStatuses } from '@/helpers/order-status.helper'
import OrderService from "@/services/OrderService";

export default {
  name: 'OrdersGrid',
  props: {
    hasActions: {
      type: Boolean,
      default: () => true,
    },
    orders: {
      type: Array,
      default: () => [],
    },
    referenceCanRedirectOrder: {
      type: Boolean,
      default: () => false,
    },
    canRedirectClientOrders: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      sorting: {
        createdDate: 'desc',
      },
      loading: false,
      priorities: {
        [PriorityTypeEnum.Low]: 'Baja',
        [PriorityTypeEnum.Medium]: 'Media',
        [PriorityTypeEnum.High]: 'Alta',
      },
    }
  },
  computed: {
    restrictedStatuses() {
      const {
        InProgress,
        ToBeDelivered
      } = getRestrictedStatuses(this.$store.state.catalogs.orderStatuses);

      return {
        InProgress,
        ToBeDelivered
      };
    },
    filteredStatuses() {
      // return active statuses
      return this.$store.state.catalogs.orderStatuses.filter((status) => status.isActive);
    },
  },
  methods: {
    getDeviceModel(order) {
      return order.orderDevices[0]?.deviceModel?.model || order.orderDevices[0]?.deviceModel || '';
    },
    getAssignedAccountName(order) {
      if (order.assignedToAccount) return order.assignedToAccount.fullname;
      else return '';
    },
    showOrderNotesManager(orderId) {
      OrderNotesManagerService.show(orderId);
    },
    async updateStatus(orderId, status) {
      const order = this.orders.find((o) => o.id === orderId);
      this.whatsapp(status, order);
      this.$emit('onStatusUpdate', { orderId, status });
    },
    whatsapp(status, order) {
      const message = `¿Deseas notificar al cliente ${order.client.fullName} por WhatsApp?`;
      const options = { type: 'warning', confirmButtonText: 'SI', cancelButtonText: 'NO' };
      const countryCode = getCountryCode(
        this.$store.state.catalogs.flags,
        order.client.phoneCountryCodeId,
      );
      if (!countryCode) {
        this.$toastr.e('El Cliente debe tener registrado el Código de Area');
        return;
      }
      switch (status) {
        case this.restrictedStatuses.InProgress.id:
          this.$confirm(message, options).then(async () => {
            Whatsapp({
              code: countryCode,
              phone: order.client.phone,
              text: `Tu orden ${order.reference} ya se está trabajando`,
            });
            await OrderService.addHistoryWhatsapp({
              orderId: order.id,
              message: `Tu orden ${order.reference} ya se está trabajando`,
            });
          });
          break;
        case this.restrictedStatuses.ToBeDelivered.id:
          this.$confirm(message, options).then(async () => {
            Whatsapp({
              code: countryCode,
              phone: order.client.phone,
              text: `Hola tu orden ${order.reference} está lista para recogerse`,
            });
            await OrderService.addHistoryWhatsapp({
              orderId: order.id,
              message: `Hola tu orden ${order.reference} está lista para recogerse`,
            });
          });
          break;
        default:
      }
    },
    moneyFormat(value) {
      const { currencySymbol } = this.$store.state.account;
      return numbers.moneyFormat(value, currencySymbol);
    },
    onEdit(order) {
      this.$router.push({ name: 'orders.read', params: { id: order.id } });
    },
    onPrint(order) {
      this.$router.push({ name: 'invoices.default', params: { id: order.id }});
    },
    async onDelete(orderId) {
      if (!this.$store.state.account.canEditOrders) {
        return;
      }
      const order = this.orders.find((o) => o.id === orderId);
      this.$confirm(`¿Está seguro que desea eliminar la orden ${order.reference}?`, {
        type: 'warning',
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancelar',
      }).then(async () => {
        this.$emit('onDeleteOrder', orderId);
      });
    },
    sort(column) {
      this.sorting[column] = this.sorting[column] === 'asc' ? 'desc' : 'asc';
      this.$emit('onSorting', this.sorting);
    },
    getOrderStatusColor(status) {
      if (!status) {
        return null;
      }
      return status.colour;
    }
  },
}
</script>
