<template>
  <div
    class="px-4 py-4 bg-white border border-purple-500 rounded-2xl
      shadow cursor-pointer transition duration-300 ease-in-out transform
      hover:-translate-y-1 hover:bg-gray-200"
    :class="{ 'disabled': disabled }"
    @click="handleClick">
    <div>
      <slot />
    </div>
  </div>
</template>
<style scoped>
.disabled {
  cursor: not-allowed;
}
.disabled:hover {
  background-color: inherit;
  transform: translateY(0);
}
</style>
<script>
export default {
  name: 'CardButton',
  props: {
    title: {
      type: String,
      default: () => null
    },
    name: {
      type: String,
      default: () =>  null
    },
    params: {
      type: Object,
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    handleClick() {
      if (this.disabled) return;
      this.$router.push({ name: this.name, params: this.params });
    },
  },
}
</script>
